import {
  ThemeOptions,
  alpha,
  createTheme,
} from "@mui/material";

import BoxShadowConstants from "@/public/styles/boxShadowConstants";
import { ButtonFontWeight } from "@/public/styles/fontConstants";
import {
  BackgroundColors,
  DisabledColors,
  ErrorColors,
  PrimaryColors,
  SecondaryColors,
  SuccessColors,
  VariantColors,
  WarningColors,
} from "@/public/styles/colorConstants";
import { ButtonVariant } from "./variants/button";
import {
  TypographyCommon,
  TypographyVariant,
  roboto,
} from "./variants/typography";
import { InputVariant } from "./variants/input";
import { SelectVariant } from "./variants/select";
import { AutocompleteVariant } from "./variants/autocomplete";
import { TextFieldVariant } from "./variants/textField";
import { IconButtonVariant } from "./variants/iconButton";
import { SvgIcon } from "./variants/svgicon";
import { scrollBarHeight, scrollBarWidth } from "../styles/scrollbar";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import * as CalendarVariants from "./variants/calendar";
import { PaperRadius } from "../styles/spacingConstants";
import zIndex from "@mui/material/styles/zIndex";
import { ChipVariant } from "./variants/chip";

const originTheme = createTheme({
  spacing: (...rest: any) => {
    if (rest.length > 0)
      return rest.map((item: number) => `${item / 2}rem`).join(" ");
    return `${rest / 2}rem`;
  },
  palette: {
    primary: {
      main: PrimaryColors.Primary30,
      light: PrimaryColors.Primary10,
      dark: PrimaryColors.Primary50,
      90: PrimaryColors.Primary90,
      70: PrimaryColors.Primary70,
      30: PrimaryColors.Primary30,
      10: PrimaryColors.Primary10,
      5: PrimaryColors.Primary05,
      0: PrimaryColors.Primary00,
    },
    secondary: {
      main: SecondaryColors.Secondary30,
      light: SecondaryColors.Secondary10,
      dark: SecondaryColors.Secondary50,
      90: SecondaryColors.Secondary90,
      70: SecondaryColors.Secondary70,
      50: SecondaryColors.Secondary50,
      30: SecondaryColors.Secondary30,
      10: SecondaryColors.Secondary10,
      5: SecondaryColors.Secondary05,
    },
    variant: {
      main: VariantColors.Variant30,
      light: VariantColors.Variant20,
      dark: VariantColors.Variant60,
      90: VariantColors.Variant90,
      80: VariantColors.Variant80,
      60: VariantColors.Variant60,
      30: VariantColors.Variant30,
      20: VariantColors.Variant20,
      10: VariantColors.Variant10,
      5: VariantColors.Variant05,
      0: VariantColors.Variant00,
    },
    error: {
      main: ErrorColors.Error30,
      light: ErrorColors.Error20,
      dark: ErrorColors.Error60,
      90: ErrorColors.Error90,
      80: ErrorColors.Error80,
      60: ErrorColors.Error60,
      30: ErrorColors.Error30,
      20: ErrorColors.Error20,
      10: ErrorColors.Error10,
    },
    warning: {
      main: WarningColors.Warning60,
      light: WarningColors.Warning30,
      dark: WarningColors.Warning90,
      90: WarningColors.Warning90,
      80: WarningColors.Warning80,
      10: WarningColors.Warning10,
    },
    success: {
      main: SuccessColors.Success30,
      light: SuccessColors.Success20,
      dark: SuccessColors.Success60,
      90: SuccessColors.Success90,
      80: SuccessColors.Success80,
      60: SuccessColors.Success60,
      30: SuccessColors.Success30,
      20: SuccessColors.Success20,
      5: SuccessColors.Success05,
    },
    disabled: {
      main: DisabledColors.Disabled10,
      light: DisabledColors.Disabled05,
      dark: DisabledColors.Disabled20,
      5: DisabledColors.Disabled05,
      30: DisabledColors.Disabled30,
      60: DisabledColors.Disabled60,
    },
    background: {
      main: BackgroundColors.Background05,
      light: BackgroundColors.Background00,
      dark: BackgroundColors.Background10,
      darker: BackgroundColors.Background20,
    },
  },
  typography: TypographyCommon,
});

// Create a theme instance.
const theme = createTheme(originTheme, {
  typography: TypographyCommon,
  palette: {
    disabled: originTheme.palette.disabled,
    variant: originTheme.palette.variant,
    buttonPrimary: originTheme.palette.augmentColor({
      color: {
        dark: PrimaryColors.Primary30,
        main: PrimaryColors.Primary10,
        light: PrimaryColors.Primary05,
      },
    }),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
          overscrollBehavior: "contain",

          "&::-webkit-scrollbar": {
            width: scrollBarWidth,
            height: scrollBarHeight,
            position: "absolute",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: alpha(VariantColors.Variant0, 0.5),
          },
          "&.no-track::-webkit-scrollbar-track": {
            backgroundColor: 'transparent',
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: VariantColors.Variant05,
            borderRadius: 4,
          },
          "&::-webkit-scrollbar-corner": {
            backgroundColor: "transparent",
          },
        },
        // ellipsis placeholder text
        "[placeholder]": {
          textOverflow: "ellipsis",
        },
        "::-webkit-input-placeholder": {
          /* Chrome/Opera/Safari */ textOverflow: "ellipsis",
        },
        "::-moz-placeholder": {
          /* Firefox 19+ */ textOverflow: "ellipsis",
        },
        ":-ms-input-placeholder": {
          /* IE 10+ */ textOverflow: "ellipsis",
        },
        ":-moz-placeholder": {
          /* Firefox 18- */ textOverflow: "ellipsis",
        },
        "@font-face": {
          fontFamily: roboto.style.fontFamily,
          fontDisplay: "swap",
        },
        body: {
          backgroundColor: BackgroundColors.Background05,
          minHeight: "100%",
          width: "100%",
          margin: 0,
          minWidth: 400,
          padding: "0 !important",
        },
        html: {
          overflow: 'hidden',
          height: "100%",
          fontSize:
            "min(calc(12px + (16 - 12) * (100vw - 300px) / (800 - 300)), 16px)",
          // fontSize: 16,
        },
        ":root": BoxShadowConstants,
        a: {
          textDecoration: "none",
          color: "inherit",
        },
        ["a.highlight-link"]: {
          textDecoration: "none",
          display: "inline-block",
          color: originTheme.palette.primary.main,
          "&:hover,:active": {
            transform: "scale(1.1)",
            color: originTheme.palette.primary.light,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          ".MuiPaper-root": {
            borderRadius: PaperRadius,
          },

          ".MuiBackdrop-root": {
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          ["& > .MuiDivider-vertical.MuiDivider-flexItem"]: {
            marginRight: -1,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: PrimaryColors.Primary00,
          borderWidth: "0 1px 1px 0",
          borderStyle: "solid",
          borderColor: "transparent",
          color: PrimaryColors.Primary70,
        },
        body: {
          borderColor: PrimaryColors.Primary00,
          borderStyle: "solid",
          borderWidth: "0 1px 1px 0",

          ["&:last-child"]: {
            borderRightWidth: 0,
          },
        },
        stickyHeader: ({ theme }) => ({
          zIndex: theme.zIndex.drawer
        })
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "var(--shadow-secondary-center)",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: originTheme.spacing(1.5),
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: originTheme.spacing(3),
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: ({ ownerState: { color }, theme }) => ({
          fontWeight: ButtonFontWeight,
        }),
      },
    },
    MuiButton: ButtonVariant,
    MuiMenu: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: originTheme.spacing(0.5),
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: originTheme.palette.variant.main,
          minHeight: "3rem",
          paddingTop: ".375rem",
          paddingBottom: ".375rem",
          fontWeight: 500,
          "&.item-error": {
            color: originTheme.palette.error.main,
          },
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained",
        size: "medium",
      },
    },
    MuiFormControl: {
      variants: [
        {
          props: { size: "medium" },
          style: {
            [originTheme.breakpoints.down("sm")]: {
              minWidth: "13.75rem",
            },
          },
        },
      ],
      styleOverrides: {
        root: ({ ownerState: { margin }, theme }) => ({
          margin: margin === "normal" ? originTheme.spacing(1, 0) : undefined,
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: ({ ownerState: { color }, theme }) => ({
          width: "100%",
          fontSize: "0.875rem",
          fontWeight: 500,

          "&.Mui-focused, &.MuiInputLabel-shrink": {
            fontWeight: 700,
          },
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: originTheme.palette.variant.main,

          "&.MuiInputLabel-root": {
            maxHeight: "1.5rem",
            lineHeight: 1.125,
            minHeight: "1rem",
          },
        },
        asterisk: {
          color: originTheme.palette.error.main,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ ownerState: { color }, theme }) => ({
          width: "100%",
          fontSize: "0.875rem",
          fontWeight: 500,

          color: color ? theme.palette[color].main : undefined,

          "&.Mui-focused, &.MuiInputLabel-shrink": {
            fontWeight: 700,
          },
        }),
      },
    },
    MuiInputBase: InputVariant,
    MuiTextField: TextFieldVariant,
    MuiAutocomplete: AutocompleteVariant,
    MuiSelect: SelectVariant,
    MuiSvgIcon: SvgIcon,
    MuiChip: ChipVariant,
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: VariantColors.Variant0,
          borderBottomWidth: 1.5,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha("#777F8B", 0.8),
        },
        invisible: {
          backgroundColor: "transparent",
          backdropFilter: "none",
        },
      },
    },
    MuiIconButton: IconButtonVariant,
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          "&.Mui-selected": {
            color: PrimaryColors.Primary10,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: PrimaryColors.Primary10,
        },
      },
    },
    MuiTypography: TypographyVariant,
    ...CalendarVariants,
  },
} as ThemeOptions);

export default theme;
