import EposArrowDownIcon from "@/public/icons/common/ArrowDown.icon";
import EposClearIcon from "@/public/icons/common/Clear.icon";
import { ButtonRadius } from "@/public/styles/spacingConstants";
import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  CSSInterpolation,
  Theme,
} from "@mui/material/styles";
import { display } from "@mui/system";
import { createElement } from "react";

export const AutocompleteVariant: {
  defaultProps?: ComponentsProps["MuiAutocomplete"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiAutocomplete"];
  variants?: ComponentsVariants["MuiAutocomplete"];
} = {
  defaultProps: {
    size: "medium",
    popupIcon: createElement(EposArrowDownIcon, { fontSize: "1.25rem" }),
    slotProps: {
      popupIndicator: {
        size: "small",
      },
      clearIndicator: {
        size: "small",
      },
    },
    ListboxProps: {
      className: 'no-track'
    },
    clearIcon: createElement(EposClearIcon, { fontSize: "1rem" }),
  },
  styleOverrides: {
    root: ({ theme }) => ({
      "& .MuiInputBase-root": {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,

        "&.MuiAutocomplete-input": {
          padding: "auto",
        },
      },
    }),
    popupIndicator: ({ theme, ownerState: { multiple } }) => {

      const mutilpleStyles: CSSInterpolation & Record<string, CSSInterpolation> = multiple ? {
        borderRadius: ButtonRadius,
        background: "#fff",
        border: `1.5px solid ${theme.palette.variant[0]}`,
        '& *': {
          color: theme.palette.variant[60],
        },

        "&:hover": {
          color: "#fff",
          background: theme.palette.variant.light,
          border: `1.5px solid ${theme.palette.variant.light}`,

          '& *': {
            color: "#fff",
          },
        },

        '& .MuiTouchRipple-root': {
          display: "none",
        }
      } : {}

      return ({
        width: "1.75rem",
        height: "1.75rem",
        ...mutilpleStyles
      })
    },
    clearIndicator: {
      width: "1.75rem",
      height: "1.75rem",
    },
    option: ({ theme }) => ({
      color: theme.palette.variant[30],
      fontWeight: 500,
      padding: `${theme.spacing(1, 1.5)}!important`,
    }),
    paper: ({ theme }) => ({
      marginTop: theme.spacing(1),
      borderRadius: "8px",
    }),
    tag: {
      fontSize: "1em",
    },
    endAdornment: ({ theme }) => ({
      top: "50%",
      transform: "translateY(-50%)",
      right: theme.spacing(1.5),
      display: "inline-flex",
      gap: theme.spacing(1),
    }),
    listbox: ({ theme }) => ({
      padding: 0,
      margin: theme.spacing(1, 0),
    })
  },
};
