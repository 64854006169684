import { DisabledColors, ErrorColors, PrimaryColors, SecondaryColors, SuccessColors, WarningColors } from "@/public/styles/colorConstants";
import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from "@mui/material/styles";

export const ChipVariant: {
  defaultProps?: ComponentsProps["MuiChip"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiChip"];
  variants?: ComponentsVariants<Theme>["MuiChip"];
} = {
  defaultProps: {
    variant: 'filled',
  },
  styleOverrides: {
    root: ({ theme, ownerState: { variant, size, color, fullWidth } }) => ({
      height: "2rem",
    }),
    label: {
      fontWeight: 500,
      alignItems: "center",
      "& > div": {
        lineHeight: 1,
      },
    },
  },
  variants: [
    {
      props: { color: "default" },
      style: {
        background: DisabledColors.Disabled05,
        color: DisabledColors.Disabled20,
      },
    },
    {
      props: { color: "primary" },
      style: {
        background: PrimaryColors.Primary00,
        color: PrimaryColors.Primary30,
      },
    },
    {
      props: { color: "success" },
      style: {
        background: SuccessColors.Success05,
        color: SuccessColors.Success30,
      },
    },
    {
      props: { color: "secondary" },
      style: {
        background: SecondaryColors.Secondary10,
        color: SecondaryColors.Secondary30,
      },
    },
    {
      props: { color: "error" },
      style: {
        background: ErrorColors.Error10,
        color: ErrorColors.Error30,
      },
    },
    {
      props: { color: "warning" },
      style: {
        background: WarningColors.Warning10,
        color: WarningColors.Warning60,
      },
    },
  ],
};
